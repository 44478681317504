.accordion {
    [data-toggle="collapse"]:not(.collapsed) {
        .collapsed-plus {
            transform: rotate(45deg);
            transition: transform .5s;
        }
    }
    [data-toggle="collapse"].collapsed {
        .collapsed-plus {
            transform: rotate(0deg);
            transition: transform .5s;
        }
    }
}