// Bourbon
@import "bourbon";


// Bootstrap variables
// @import "../shared/bootstrap/bootstrap-4.1.3/scss/functions";
// @import "../shared/bootstrap/bootstrap-4.1.3/scss/variables";
// @import "../shared/bootstrap/bootstrap-4.1.3/scss/mixins";

// Theme
@import "theme/variables";

// Content
@import "components/scrolling";
@import "components/forms";
@import "components/first";
@import "components/collapse";
@import "components/preview";

// Bootstrap
@import '~bootstrap/scss/bootstrap.scss';

@import '~typeface-barlow/index.css';
